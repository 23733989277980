import React, { useState } from "react"
import { Box, Grid, Divider, Button, Collapse } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import dpcgclogo from "../images/dpcgc/dpcgclogo.svg"
import newicon from "../images/dpcgc/newicon.svg"
import Up from "../images/dpcgc/up.svg"
import Down from "../images/dpcgc/down.svg"
import { Link } from "gatsby"
import { dpcgcFAQ, furtherFAQ, grievanceFAQ } from "../components/dpcgc/faqData"
import "../components/dpcgc/faq.css"

const useStyles = makeStyles(theme => ({
  rooot: {
    width: "100%",
    background: "#243974",
    // height: "22rem",
    padding: "8.5rem 0 2rem 0",
  },
  dpcgclogo: {
    height: "11rem",
  },
  iamailogo: {
    height: "4rem",
    margin: "2rem",
  },
  nav: {
    color: "#243974",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  grid: {
    margin: "10px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 10px",
    },
  },
  othernav: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  blueheading: {
    fontSize: "26px",
    lineHeight: "30px",
    color: "#243974",
  },
  question: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#4F4F4F",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  answer: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#828282",
    margin: "0 0 25px 0",
    textAlign:'justify',
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  newicon: {
    height: "16px",
    [theme.breakpoints.down("md")]: {
      height: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "10px",
    },
  },
}))

const Expand = ({ heading, text, classes }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        onClick={e => {
          setShow(!show)
        }}
        style={{ cursor: "pointer", margin: "10px 0" }}
      >
        <Grid item sm={11} xs={11}>
          <Typography className={classes.question}>{heading}</Typography>
        </Grid>
        <Grid item sm={1} xs={1} style={{ textAlign: "right" }}>
          <img src={show ? Up : Down} alt="Arrow Down" />
        </Grid>
      </Grid>
      <Collapse in={show} timeout={300}>
        <p
          className={classes.answer}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Collapse>
    </>
  )
}

export default function CommonHeader() {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.rooot}>
        <Grid container justifyContent="center">
          <Grid item>
          <Link to="/">
              <img
                src={dpcgclogo}
                alt="dpcgc logo"
                className={classes.dpcgclogo}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0} justifyContent="center">
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/whoweare" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>ABOUT US</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/grbmembers" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>GRB MEMBERS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/govtnotification" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              GOVT. NOTIFICATIONS
            </Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/discolsures" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>DISCLOSURES</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/faqs" style={{ textDecoration: "none" }}>
            <Typography className={classes.nav}>FAQS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/leadersspeak" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>LEADERSHIP TEAM</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
        <Box height="16px" />
          {/* <Box style={{ textAlign: "right" }}>
          <img src={newicon} alt="new" className={classes.newicon} />
          </Box> */}
          <Link to="/consumerresources" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              CONSUMER RESOURCES
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Box height="7rem" />
      <Grid container justifyContent="center">
        <Grid item md={7} sm={8} xs={10} style={{paddingBottom:"30px"}}>
          <Typography className={classes.blueheading}>FAQs</Typography>
        </Grid>
        <Grid item md={7} sm={8} xs={10} style={{paddingBottom:"30px"}}>
          <Grid container justifyContent="space-between" style={{padding:"10px 0"}}>
            <Grid item md={3} sm={4} xs={8}>
              <Typography className={classes.blueheading}>On DPCGC</Typography>
            </Grid>
            <Grid item md={8} sm={7} xs={11}>
              {dpcgcFAQ.map((item, index) => {
                const title = Object.keys(item)[0]
                const value = item[title]
                return (
                  <>
                    <Expand heading={title} text={value} classes={classes} />
                    {index < dpcgcFAQ.length - 1 && (
                      <Divider style={{ background: "black" }} />
                    )}
                  </>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Box height="4rem" />
        <Grid item md={7} sm={8} xs={10} style={{paddingBottom:"30px"}}>
          <Grid container justifyContent="space-between">
            <Grid item md={3} sm={4} xs={8} style={{padding:"10px 0"}}>
              <Typography className={classes.blueheading}>
                On grievances
              </Typography>
            </Grid>
            <Grid item md={8} sm={7} xs={11}>
              {grievanceFAQ.map((item, index) => {
                const title = Object.keys(item)[0]
                const value = item[title]
                return (
                  <>
                    <Expand heading={title} text={value} classes={classes} />
                    {index < grievanceFAQ.length - 1 && (
                      <Divider style={{ background: "black" }} />
                    )}
                  </>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Box height="4rem" />
        <Grid item md={7} sm={8} xs={10} style={{paddingBottom:"30px"}}>
          <Grid container justifyContent="space-between">
            <Grid item md={3} sm={4} xs={8} style={{padding:"10px 0"}}>
              <Typography className={classes.blueheading}>
              Further Information
              </Typography>
            </Grid>
            <Grid item md={8} sm={7} xs={11}>
              {furtherFAQ.map((item, index) => {
                const title = Object.keys(item)[0]
                const value = item[title]
                return (
                  <>
                    <Expand heading={title} text={value} classes={classes} />
                    {index < furtherFAQ.length - 1 && (
                      <Divider style={{ background: "black" }} />
                    )}
                  </>
                )
              })}
            </Grid>
          </Grid>
          <Box height="4rem" />
        </Grid>
      </Grid>
    </>
  )
}
