import React from "react"
import faqpdf from '../../../static/faq.pdf'

export const dpcgcFAQ = [
  {
    "What is the DPCGC?": `Digital Publisher Content Grievances Council (DPCGC) is an officially recognized, independent self-regulatory body for online curated content providers (OCCPs). It has been set up under the aegis of Internet and Mobile Association of India (IAMAI), in June 2021.
    <br><br>DPCGC is registered by the Ministry of Information & Broadcasting as a Level-II Self-Regulatory Body for publishers of online curated content, under the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021.`,
  },
  {
    "Is DPCGC recognized officially?": `DPCGC has been constituted in accordance with the provisions of the IT Rules 2021 and has agreed to perform the functions laid down in the rules. DPCGC is recognized and registered by the Ministry as a Level II Self-Regulatory Body for online curated content providers.`,
  },
  {
    "Who are the members of DPCGC?": `You can see the complete list of current DPCGC members <a href="/scrolltomembers" target="_blank">here</a>.`,
  },
  {
    "What is the GRB?": `The Grievance Redressal Board (or GRB) is constituted under the DPCGC to address complaints pertaining to online curated content on any of the DPCGC member platforms.<br><br>
      Through the Grievance Redressal Board (GRB), DPCGC aims to usher in a redressal mechanism which ensures a balance between addressing viewer complaints fairly and showcasing content without ad-hoc interventions.
      `,
  },
]

export const grievanceFAQ = [
  {
    "Who can register a Grievance?": `Any person may escalate a complaint to the DPCGC for having not been resolved by the member company of the DPCGC within 15 days of receipt of such complaint. Any person may file an appeal before the DPCGC against decisions of the member company of the DPCGC within 15 days of receipt of such decision.`,
  },
  {
    "What can I register a Grievance about?": `You may register a grievance pertaining to any issues regarding content in relation to the Code of Ethics and procedure and safeguards in relation to Digital Media as prescribed in Rule III of the IT Rules 2021. <br><br>
      You can either register a Grievance that is an Appeal or an Escalated Complaint. An Appeal is a grievance filed before the DPCGC against decisions of the member company of the DPCGC. An Escalated Complaint is a grievance escalated to the DPCGC if the same remains unresolved by member company of DPCGC within the prescribed time frame of 15 days of receipt of such complaint.`,
  },
  {
    "How do I register a grievance?": `The complaint may be filed through the Grievance Redressal Portal on the DPCGC website. You can access it <a href="https://app.dpcgc.org" target="_blank">here</a>.`,
  },
  {
    "What happens if the grievance is not filed in the correct format?": `It will be returned if not filed within the specific format given on the DPCGC portal. The DPCGC Secretariat may call upon the Complainant/Appellant to re-file in accordance with the format.`,
  },
  {
    "Can I file a grievance directly before the DPCGC without first making a complaint before the DPCGC member company?": `No`,
  },
  {
    "In which language can the complaint be made?": `The complaint can be made in English.`,
  },
  {
    "What is the timeline for disposal of a Grievance by the DPCGC?": `The Grievance Redressal Board shall pass final orders within 15 days of the receipt of a Grievance.`,
  },
  {
    "What happens to my Grievance once it is placed before the DPCGC?": `
      <div class="mydiv">  
    <ul type="circle">
      <li>All Appeals / Escalated Complaints will be verified to be complete and valid by the Secretariat within 3 working days of their receipt. If the Appeal / Escalated Complaint is bereft of any essential particulars which is necessary for the adjudication thereof, the Secretariat may call upon the Complainant/Appellant to provide such information as may be required. The Secretariat may register the Appeal as a Registered Grievance and a registration number shall be assigned to the same.</li><br>
      <li>Upon registration of the Appeal/Escalated Complaint as a Registered Grievance, the Secretariat will issue an email on the same day intimating the concerned Complainant and DPCG Council member of the registration number of such Registered Grievance, the date of the sitting/hearing of the GRB for adjudication of the Registered Grievance and provide both parties an opportunity to appear/represent themselves on the said date before the GRB.</li><br>
      <li>GRB will hold a sitting/hearing on the Registered Grievance and may fix a second hearing, if required. GRB shall pass a reasoned guidance/advisory/order disposing the Registered Grievance and the parties will be informed of the same.</li><br>
      <li>The GRB after following the due process of Grievance Redressal Mechanism shall pass reasoned guidance/advisory/order within 15 working days of registration of the Registered Grievance.</li><br>
      <li>Upon compliance of the due process of Grievance Redressal Mechanism, if the GRB concludes that the DPCG Council member against whom the Registered Grievance is filed, has violated the Code of Ethics, the GRB may consider passing any of the guidance or advisory as detailed in the IT Rules 2021.</li>
      </ul></div>`,
  },
  {
    "Does the Complainant/Appellant have the option of being represented through a legal representative at the sitting/hearing of their Registered Grievance?": `Yes`,
  },
  {
    "How is the decision of Grievance Redressal Board taken?": `All decisions are taken after extensive review of the information submitted in the complaint and decided by way of a simple majority.`,
  },
  {
    "What kind of guidance/advisory/direction can be passed by the GRB while dealing with a grievance?": `The GRB while disposing a grievance or an appeal may issue following guidance or advisories to the publishers as under:<br><br>
   <div class='mydiv'> <ol type="a">
        <li>warning, censuring, admonishing or reprimanding the publisher;</li><br>
        <li>requiring an apology by the publisher;</li><br>
        <li>requiring the publisher to include a warning card or a disclaimer;</li><br>
        <li>
        in case of online curated content, direct the publisher to,—<br><br>
        <div class="mydiv">
        <ol type"i">
                    <li>reclassify ratings of relevant content;</li><br>
                    <li>make appropriate modification in the content descriptor, age classification and access control measures;</li><br>
                    <li>edit synopsis of relevant content;</li>
                    </ol>
                    </div>
        </li>
    </ol></div>
      `,
  },
  {
    "What happens if the guidance/advisory/direction of DPCGC Grievance Redressal Board is not adhered to by the DPCGC Member?": `If any DPCG Council member fails to comply with the guidance or advisory of the GRB within such timelines as may be specified therein, then the GRB shall refer such non-compliance to the Oversight Mechanism.`,
  },
]

export const furtherFAQ = [
  {
    "Where can I read IT Rules 2021 in detail?": `The rules can be accessed on the website of the <a href=${faqpdf} target="_blank">Ministry of Broadcasting</a>.`,
  },
  {
    "What do OCC and OCCP stand for?": `OCC stands for Online Curated Content and OCCP for Online Curated Content Providers.`,
  },
  {
    "What is the Code of Ethics for OCCPs?": `The Code of Ethics for OCC platforms includes the following elements:<br><br>
      <div class="mydiv"><ul> 
      <li>General Principles related to prohibited content and content regarding which the platforms need to observe due caution and discretion</li><br>
      <li>Content classification and rating of content as per various age-based categories</li><br>
      <li>Display of content classification and content descriptors</li><br>
      <li>Age-rating, parental locks and age verification mechanisms for restriction of access to certain content by children</li><br>
      <li>Measures to improve accessibility of content for persons with disabilities</li>
      </ul></div>
      `,
  },
  {
    "What are the general principles required to be observed by the OCCPs as part of the Code of Ethics?": `
    <div class="mydiv"><ul type="square">
      <li>Not to publish any content which is prohibited under any law or prohibited by any court of competent jurisdiction</li><br>
      <li>Take into consideration the implications, and exercise due caution and discretion in respect of the content which:<br><br>
      <div class="mydiv">
        <ul>
        <li>Affects the sovereignty and integrity of India</li><br>
        <li>Threatens, endangers or jeopardises the security of the State</li><br>
        <li>Detrimental to India‘s friendly relations with foreign countries</li><br>
        <li>Likely to incite violence or disturb the maintenance of public order</li><br>        
        </ul>
      </div>
      </li>
      <li>Take into consideration India‘s multi-racial and multi-religious context and exercise due caution and discretion when featuring the activities, beliefs, practices, or views of any racial or religious group.</li>
      </ul></div>`,
  },
]
